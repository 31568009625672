import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './company-preview.module.css'

export default ({ company }) => (
  <div className={styles.preview}>    
    <h3 className={styles.previewTitle}>
      <Link to={`/company/${company.slug}`}>{company.companyName}</Link>
    </h3>    
    <p>{company.shortDescription}</p>
  </div>
)
