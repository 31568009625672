import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import styles from './directory.module.css'
import Layout from '../components/layout'
import CompanyFeaturedPreview from '../components/company-featured-preview'
import CompanyPreview from '../components/company-preview'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class CompanyIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const companies = get(this, 'props.data.allContentfulCompany.edges');    

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <div className={styles.hero}>Directorio</div>
          <Container>
          <div className="wrapper">
            <h2 className="section-headline">Empresas Destacadas</h2>
            <ul className="article-list">
              {companies.filter(( {node} ) => { return node.isFeatured === true }).slice(0, 4).map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <CompanyFeaturedPreview company={node} />                    
                  </li>
                )
              })}
            </ul>
          </div>
          </Container>
          <Container>
          <div className="wrapper">
            <h2 className="section-headline">Empresas</h2>
            <ul className="article-list">
              {companies.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <CompanyPreview company={node} />                    
                  </li>
                )
              })}
            </ul>
          </div>
          </Container>
          
        </div>
      </Layout>
    )
  }
}

export default CompanyIndex

export const pageQuery = graphql`
  query CompanyIndexQuery {
    allContentfulCompany {
      edges {
        node {
          companyName
          slug
                   
          description {
            childMarkdownRemark {
              html
            }
          }
          isFeatured
          shortDescription          
        }
      }
    }
  }
`
